import React, { useEffect, useState, useContext } from 'react'
import { ApiService } from 'services'

import { Col, FormGroup, Input, Row } from 'reactstrap'

import { UserContext } from 'context'

import CollapsibleCard from '../../components/CollapsibleCard'

function RequestFilters(props) {
  const { changeFilter } = props;

  const { getCurrentUser } = useContext(UserContext)
  const user = getCurrentUser()

  const [insurers, setInsurers] = useState([])

  useEffect(() => {
    ApiService.getAll('insurers')
    .then(res => {
      if (!res.status || res.status !== 'ok') {
        return
      }

      setInsurers(res.payload)
    })
    .catch(err => {
      alert('Ha ocurrido un error al obtener el listado de Obras Sociales, temporalmente no se podrá filtrar por obra social.')
      console.log(err)
    })
  }, [])

  return (
    <CollapsibleCard title="Filtros">
      <Row>
        <Col sm={4}>
          <FormGroup>
            <label>Número de Remito</label>
            <Input type="text" onChange={ (event) => changeFilter('numeroRemito', event.target.value) } />
          </FormGroup>
        </Col>
        { user.roles.some(r => [1, 3, 4].includes(r))
          ? <Col sm={4}>
              <FormGroup>
                <label>Obra Social</label>
                <Input type="select" onChange={ (event) => changeFilter('ooss_id', event.target.value) } defaultValue="any">
                  <option value="any">Todas</option>
                  {
                    insurers && Array.isArray(insurers)
                    ? insurers.map(insurer => <option key={'insurer_' + insurer.id} value={ insurer.id }>{ insurer.codigo }</option>)
                    : null
                  }
                </Input>
              </FormGroup>
            </Col>
          : null
        }
      </Row>
    </CollapsibleCard>
  )
}

export default RequestFilters
