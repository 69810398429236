import React, { useEffect, useState } from 'react'
import { Document, Page } from 'react-pdf'
import { Row, Col } from 'reactstrap'
import { ApiService, attachs as attachNames } from 'services'
import AlertModal from 'components/AlertModal'
import config from 'config'
import { getUTCOffset } from 'helpers/getUTCOffset'
import moment from 'moment'
import Zoom from 'react-medium-image-zoom'
import { useAlertPlus } from 'hooks'

import 'react-medium-image-zoom/dist/styles.css'

function Benefit (props) {
  const { id } = props

  const [ files, setFiles ] = useState([])

  const [ formData, setFormData ] = useState({
    insurer_id: null,
    insurer_external_id: null,
    authorization_id: null,
    recipient_id: null,
    recipient_name: null,
    recipient_age: null,
    prescription_date: null,
    diagnosis: null,
    impairment: false,
    sessions: [],
    practice_id: null,
    additional_id: null,
    files: {},
    previously_authorized: null,
    effector_id: null,
    max_sessions: 5
  })

  const [ showAlert, message, alertCallback, alert ] = useAlertPlus('')
  const [ isLoading, setLoading ] = useState(true)

  useEffect(() => {
    ApiService.getOne('requests', id)
    .then(res => {
      if (!res.id) {
        alert('Se produjo un error al intentar recuperar la información de la presentación. Por favor, intente nuevamente más tarde')
        return
      }

      const data = formData

      // Parseamos las fechas en objetos Date para el DatePicker
      if (res.fecha_prescripcion_medica) {
        data.prescription_date = new Date(`${ res.fecha_prescripcion_medica }${ getUTCOffset() }`)
      }

      if (res.authorization_date) {
        data.authorization_date = new Date(`${ res.authorization_date}${ getUTCOffset() }`)
      }

      // Parseamos las fechas en objetos Date para el DatePicker
      res.sessionDates.forEach((session) => {
        data.sessions.push({
          id: session.id,
          date: new Date(`${ session.date }${ getUTCOffset() }`),
          authorization: session.approvalNumber
        })
      })

      data.sessions.sort((a, b) => a.date - b.date)

      data.previously_authorized = res.aprobacion_previa

      if (res.numero_afiliado) {
        data.recipient_age = res.affiliate.edad
        data.recipient_id = res.affiliate.numero_afiliado
        data.recipient_name = res.affiliate.nombre_apellido
      }

      if (res.matriculado_id) {
        data.matriculate_id = res.matriculate?.matricula
        data.matriculate_name = res.matriculate?.apellido || res.matriculate?.nombre ? `${ res.matriculate?.apellido } ${ res.matriculate?.nombre }`.trim() : null
      }

      data.diagnosis = res.diagnostico

      data.impairment = res.prestacion_discapacidad ?? false

      data.practice_id = res.idTipoPractica
      data.practice_text = res.tipo_practica
      data.additional_id = res.idPracticaAdicional
      data.additional_text = res.practica_adicional

      data.authorization_id = res.numero_aprobacion

      if (res.ooss) {
        data.insurer_external_id = res.ooss.numero
        data.insurer_shortname = res.ooss.codigo
        data.insurer_fullname = res.ooss.nombre
      }

      data.max_sessions = res.cantidad_sesiones ? parseInt(res.cantidad_sesiones) : 5

      if (res.attachs && Array.isArray(res.attachs)) {
        const newFiles = []
        res.attachs.forEach(file => {
          newFiles.push({
            id: file.tipo,
            type: file.tipo_archivo === 'pdf' ? 'application/pdf' : 'image/jpeg', // En realidad aparte de application/pdf, el otro tipo no importa
            url: config.baseUrl + '/' + file.url,
            label: attachNames[file.tipo]
          })
        })

        setFiles(newFiles)
      }

      // Actualizamos la información del estado
      setFormData(data)
    })
    .catch(err => {
      setLoading(false)
      alert('Se produjo un error al intentar recuperar la información de la presentación. Por favor, intente nuevamente más tarde')
      console.log(err)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <>
    <Row>
      <Col sm={6}>
        <table>
          <tbody>
          <tr className={ formData.insurer_id !== 57 ? '' : 'd-none' }>
            <th>Número de Aprobación</th>
            <td>{ formData.authorization_id }</td>
          </tr>
          <tr>
            <th>Matrícula</th>
            <td>{ formData.matriculate_id ?? ' - ' }</td>
          </tr>
          <tr>
            <th>Matriculado</th>
            <td>{ formData.matriculate_name ?? ' - ' }</td>
          </tr>
          <tr>
            <th>Número de Afiliado</th>
            <td>{ formData.recipient_id ?? ' - ' }</td>
          </tr>
          <tr>
            <th>Nombre del Afiliado</th>
            <td>{ formData.recipient_name ?? ' - ' }</td>
          </tr>
          <tr>
            <th>Edad del Afiliado</th>
            <td>{ formData.recipient_age ?? ' - ' }</td>
          </tr>
          <tr>
            <th>Fecha Prescripción Médica</th>
            <td>{ formData.prescription_date ? moment(formData.prescription_date, 'YYYY-MM-DD').format('DD/MM/YYYY') :  ' - ' }</td>
          </tr>
          <tr>
            <th>Diagnóstico</th>
            <td>{ formData.diagnosis ?? ' - ' }</td>
          </tr>
          <tr>
            <th>Discapacidad</th>
            <td>{ formData.impairment ? ' Si ' : ' No ' }</td>
          </tr>
          <tr>
            <th>Sesiones</th>
            <td>
              {
                formData.sessions && Array.isArray(formData.sessions)
                ? formData.sessions.map(({ id, date }) => <React.Fragment key={ `session_${ id }` }>{ moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY') }<br /></React.Fragment>)
                : null
              }
            </td>
          </tr>
          <tr>
            <th>Práctica</th>
            <td>{ formData.practice_text ?? ' - ' }</td>
          </tr>
          <tr>
            <th>Práctica adicional</th>
            <td>{ formData.additional_text ?? ' - ' }</td>
          </tr>
          </tbody>
        </table>
      </Col>
      <Col sm={6}>
        {
          Array.isArray(files) && files.length
          ? <div id={ `files-container-${ id }` } style={{ maxHeight: '60vh', overflowY: 'scroll', overflowX: 'hidden' }}>
              {
                files.map(({ id, type, url, label }) => {
                  if (type === 'application/pdf') {
                    return (
                      <div key={`file_${ id }`} className="file-container file-pdf" style={{ marginBottom: '1rem' }}>
                        <Zoom scrollableEl={ false }>
                        <Document file={ url } >
                          <Page pageNumber={1} renderMode="svg" width={430} />
                        </Document>
                        </Zoom>
                      </div>
                    )
                  } else {
                    return (
                      <div key={`file_${ id }`} style={{ marginBottom: '1rem' }}>
                        <Zoom scrollableEl={ false }>
                          <img src={ url } alt={ label } style={{ width: '100%', height: 'auto' }} />
                        </Zoom>
                      </div>
                    )
                  }
                })
              }
            </div>
          : null
        }
      </Col>
    </Row>
    <AlertModal isOpen={ showAlert } message={ message } onClose={ alertCallback } />
    </>
  )
}

export default Benefit
